import React, { useState } from 'react';
import './App.css';
import 'reactjs-popup/dist/index.css';


function App() {

  return (
    <div className="App">
      <Main />
    </div>
  );
}

const Main = () => {

  return (
    <>
      <header className="App-header">
        <h1 className='logo'>Alexandra Pak</h1>
        <img
          className='central-gif'
          src={'./under-construction.gif'} 
          alt="logo" 
        />
        <div className="footer">© Optipiska-Shlepa Studios</div>
      </header>
    </>
  )
}

export default App;
